import React from "react";
import Navbar from "../components/Navbar";
import logo from "../assets/image/logo.jpg";
import patentLogo from "../assets/image/patent-pending-logo.png";

const HomePage = () => {
  return (
    <div className="w-full relative">
      <Navbar />
      <div className="flex flex-col items-center">
        <div className="flex flex-col px-10 py-10 items-center bg-gray-800 w-full text-center">
          <p className="lg:text-intro md:text-2xl text-base font-semibold text-white text-shadow lg:leading-10 w-3/4 lg:py-5">
            Posting bail can be time-consuming and expensive. Here, you complete
            one form, and then bail bond services fight and compete for your
            business by offering the lowest cost and best terms so you can get
            the person you care about out of jail quickly and affordably.
          </p>
        </div>
        <img
          src={logo}
          alt="..."
          className="max-w-full w-3/5 h-auto align-middle"
        />
        <div className="flex justify-around px-10 py-10 bg-primary w-full flex-col md:flex-row">
          <div className="text-white mb-4">
            <p className="font-bold">FORT WORTH, TX OFFICE</p>
            <p>2833 Crockett St. #138</p>
            <p>Fort Worth, Texas 76107</p>
            <p>1-844-930-BOND</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">MAILING ADDRESS</p>
            <p>1141 N. Loop 1604 E. #105 - 429</p>
            <p>San Antonio, Texas 78232</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">SAN ANTONIO, TX OFFICE</p>
            <p>8940 Fourwinds Dr. #135</p>
            <p>Windcrest, Texas 78239</p>
            <p>1-844-930-BOND</p>
          </div>
        </div>
      </div>
      {/* <img className="fixed w-32 h-32 bottom-0 right-0" src={patentLogo} /> */}
    </div>
  );
};

export default HomePage;
