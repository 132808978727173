const ToS = () => {
  return (
    <div className="flex flex-col h-40 overflow-y-auto mb-4">
      <p className="text-xl underline font-bold mt-5">TERMS AND CONDITIONS</p>
      <p className="mt-5">
        The following terms and conditions govern all use of the
        BailBondBidsNow.com website and all content, services, available through
        the website and the mobile based version, including, but not limited to,
        the client area (collectively referred to as the BBBN).
      </p>
      <p className="mt-3">
        BBBN is owned and operated by Viability Health Services, Inc. (dba: CAYO
        Holdings, Inc. (
        <a
          className="text-blue-500 underline"
          href="http://www.mayoholdings.com/"
        >
          WWW.CAYOHOLDINGS
        </a>
        ) (collectively referred to as CAYO). BBBN is offered subject to your
        acceptance without modification of all of the terms and conditions
        contained herein and all other operating rules, policies including,
        without limitation, the BBBN privacy policy and procedures that may be
        published from time to time on BBBN by CAYO (collectively, the
        "Agreement"). Please read this Agreement carefully before accessing or
        using BBBN. By accessing or using any part of the website and/or the
        mobile application, you agree to become bound by the terms and
        conditions of this agreement.
      </p>
      <p className="text-lg font-bold mt-5">AGREEMENT</p>
      <p className="mt-3">
        The following Terms and Conditions sets forth the full, written
        intention of both parties which includes BBBN and all users and which
        supersedes all other prior Terms and Conditions.
      </p>
      <p className="text-lg font-bold mt-5">TERMS & CONDITIONS</p>
      <p className="mt-3">
        By downloading or using the website and the mobile version of BBBN, the
        following terms and conditions (“Terms of Use”) constitute an agreement
        between you and BailBondBidsNow.com (“BBBN”) and the services you use on
        our website and/or our mobile application (“Services”). You have read,
        and understand, and agree to these Terms of Use and our Privacy Policy
        and acknowledge that you, the individual are at least 18 years old. If
        you do not agree to these Terms of Use and the Privacy Notice further
        below, then you may not access BBBN and not use these Services.
      </p>
      <p className="text-lg font-bold mt-5">CHANGES TO TERMS OF USE</p>
      <p className="mt-3">
        We may revise and update these Terms of Use by posting a revised
        version. Any changes to the Terms will be effective immediately upon
        posting. Your continued use of the Services following the posting of
        revised Terms of Use meaning that you review, accept and agree to the
        changes each time. Your continued use of Services after such changes
        will constitute acceptance of, and agreement to, such changes.
      </p>
      <p className="text-lg font-bold mt-5">SERVICE PERIOD</p>
      <p className="mt-3">
        The BBBN platform shall remain operational for a minimum of 95% of time
        for use by users. Occasionally operations may need to be interrupted for
        normal maintenance. If in the event the normal maintenance time exceeds
        5% causing interruption of service to any user such user actively using
        such service will be notified.
      </p>
      <p className="text-lg font-bold mt-5">PAYMENT</p>
      <p className="mt-3">
        There is no charge or payment for any Registrant or User utilizing the
        platform BBBN service to obtain bail or a bail bond for any family
        member, friend or arrestee. Bail Bond Companies that submit Bids will be
        invoiced at the end of every month per submitted Bid based on the fees
        annotated on registration or via their Bail Bond Company profile. There
        is no obligation to satisfy or pay the provided invoice but further
        submission of Bids will no longer be accepted or permitted should said
        invoice not be paid.
      </p>
      <p className="text-lg font-bold mt-5">SCOPE AND RESTRICTION ON USE</p>
      <p className="mt-3">
        Subject to these Terms of Use, BailBondBidsNow.com, grants you a
        limited, non-exclusive, non-transferable, non-sub-licensable, revocable
        license to access and use the Services for legitimate business purposes,
        including any graphics, text, instructions, images, audio files and/or
        other sounds, videos, and other materials you may view on, access
        through, or are otherwise related to the Services (collectively, the
        “Content”). Except as otherwise provided in these Terms of Use, the
        Content may not be copied, downloaded, or stored in a retrieval system
        for any other purpose, nor may it be redistributed, reused, or modified
        for any purpose, without the express written permission of BBBN. You
        agree not to:
      </p>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(a)</p>
        <p className="mt-3">
          collect information from the Services using an automated software tool
          or manually on a mass basis;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(b)</p>
        <p className="mt-3">
          use automated means to access the Services, or gain unauthorized
          access to the Services or to any account or computer system connected
          to the Services;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(c)</p>
        <p className="mt-3">
          obtain, or attempt to obtain, access to areas of the Services or our
          system that are not intended for access by you; “flood” the Services
          with requests to otherwise overburden, disrupt, or harm the Services
          or our systems;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(d)</p>
        <p className="mt-3">
          restrict or inhibit other users from accessing or using the Services;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(e)</p>
        <p className="mt-3">
          modify or delete any copyright, trademark or other proprietary rights
          notices that appear on the App or in the Content; or
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(f)</p>
        <p className="mt-3">
          access or use the Services or Content for any unlawful purpose or
          otherwise beyond the scope of the rights granted herein.
        </p>
      </div>
      <p className="text-lg font-bold mt-5">CANCELLATION OF SERVICE</p>
      <p className="mt-3">
        Any user can cancel the service at any time. Any Family/Friend can
        simply cease using the service. Any Bonding Company may simply not pay
        the received invoice and service will terminate thereafter with no
        penalty or obligation. Likewise any user can also email support, call or
        Chat with BBBN requesting same.
      </p>
      <p className="text-lg font-bold mt-5">USER ACKNOWLEDGEMENT</p>
      <p className="mt-3">
        BBBN provides a technology platform connecting independent individuals
        seeking bail or a bail bonds for an arrestee (commonly referred to here
        as Family/Friend Seeking Release) and Bail Bonding Companies
        (collectively, “Users”) with one another. Any employment or independent
        contractor relationship entered into between or among Users is directly
        between and among the Users; BBBN, is not a party to any such
        relationship.
      </p>
      <p className="mt-3">
        You acknowledge and agree that the relationship between you BBBN, is
        limited to the use of BBBN’s marketplace. Users are neither employees
        nor independent contractors of BBBN. No joint venture,
        franchisor-franchisee, partnership or agency relationship is intended or
        created by these Terms of Use.
      </p>
      <p className="mt-3">
        The Services offered by BBBN do not include those of a a Bail Bond
        Company. BBBN, does not in any way employ, supervise, manage, direct, or
        control the work of Users in any way and does not assess, warrant, or
        guarantee the suitability, legal work status, skills, experience, or
        abilities of any User. BBBN, does not verify Users’ compliance with
        applicable laws or regulations and has no responsibility or liability
        for the acts and/or omissions of any User. Accordingly, in the event you
        transact business with other Users through the Services, you do so at
        your own risk, and you agree to use your own independent skill,
        experience, knowledge and expertise in doing so, without reliance on
        BBBN or the Services for such purposes.
      </p>
      <p className="mt-3">
        While BBBN, makes available certain aspects of the Services to Users
        free of charge, BBBN, may now or in the future invoice Bail Bond
        Companies for submitted platform bids based on the annotated pricing. A
        Bail Bond Company is not obligated to pay such invoice but should such
        invoice not be paid than future submission of bids will not be
        permitted. In addition, the usage of BBBN allows BBBN to leverage its
        database of potential Bonding Companies which as stated below includes
        the sending of texts, messages and/or emails to potential Bonding
        Companies that match the posting’s requirements or criteria.
        Accordingly, you acknowledge and agree that the receiving of these types
        of messages is acceptable and acknowledged.
      </p>
      <p className="text-lg font-bold mt-5">OWNERSHIP</p>
      <p className="mt-3">
        The Services (Including the Content) are owned by BBBN and are
        potentially or in the future may be protected under copyright,
        trademark, and other applicable United States and international laws and
        treaties Without limiting the foregoing trademarks, service marks, and
        logos displayed on this site as registered and unregistered marks of
        BBBN, and its licensors. You acknowledge and agree that, as between you
        and BBBN, BBBN is and shall remain the sole owner of the Services and
        the Content, including without limitation, all patents, copyrights,
        trademarks, trade secrets, and other intellectual property and
        proprietary rights therein and thereto. Any User further acknowledges
        and agrees that all information collected by BBBN, is subject to our
        Privacy Policy by using the Services and that you consent to all actions
        we take with respect to your information in compliance with our Privacy
        Notice.
      </p>
      <p className="text-lg font-bold mt-5">
        REGISTRATION AS A MEMBER, APPLICATION OF TERMS TO YOU; YOUR ACCOUNT AND
        SECURITY
      </p>
      <p className="mt-3">
        Access to and use of BBBN require you to register for an account. You
        are responsible for maintaining the security of your user account and
        account information, and you are fully responsible for all activities
        that occur under the account and any other actions taken in connection
        with the account.
      </p>
      <p className="mt-3">
        You agree to provide true, accurate, current and complete account
        information, about yourself as prompted by the applicable registration
        or log-in form and are responsible for keeping such information up to
        date. You are solely responsible and liable for any account information
        submitted, as well as any other activity that takes place on or through
        your account regardless of who conducts those activities. Any
        information or content submitted on or through your account is deemed to
        have been submitted by you.
      </p>
      <p className="mt-3">
        You are responsible for maintaining your account login credentials
        confidential. You may not share your username or password to any other
        person or entity or otherwise permit anyone to access or use your
        account. You are responsible for maintaining the confidentiality of your
        account information, including your username and password. You must
        immediately notify BBBN of any unauthorized uses of your account or any
        other breaches of security. BBBN is not liable for any acts or omissions
        by You, including any damages or loss of any kind incurred as a result
        of such acts or omissions from your failure to protect your username or
        password.
      </p>
      <p className="text-lg font-bold mt-5">PAYMENT PROCESSING AND INVOICING</p>
      <p className="mt-3">
        BBBN shall invoice Bail Bond Companies per submitted Bid. Payment may be
        made on the Invoice Link delivered to the provided email. There is no
        set automatic subscription as invoices again are based on the number of
        submitted Bids. If invoice is paid then future requests for bond are
        available for Bids. Should invoice not be paid then the ability to do
        future Bids is simply removed. There is no obligation to pay an received
        invoice unless the ability to submit future Bids is desired. Likewise,
        upon request only, automatic invoicing and charging is available up to a
        maximum limit set by you. Again, this automation is only upon specific
        request and will never be conducted without such specific request. All
        invoiced fees received are nonrefundable.
      </p>
      <p className="text-lg font-bold mt-5">
        CHANGE AND SUSPENSION OF SERVICES
      </p>
      <p className="mt-3">
        BBBN reserves the right to make changes to, suspend, or discontinue
        (temporarily or permanently) the Services or any portion thereof
        (Including any Content) at any time. You agree that BBBN will not be
        liable to you or to any third party for any such change, suspension, or
        discontinuance. BBBN also has the right to deny access to, and to
        suspend or terminate your access to, the Services or to any features or
        portions thereof if you violate these Terms of Use. If we suspend or
        terminate your access to Services, you will continue to be bound by the
        Terms that were in effect as of the date of your suspension or
        termination.
      </p>
      <p className="text-lg font-bold mt-5">USER GENERATED CONTENT</p>
      <p className="mt-3">
        You acknowledge and agree all content and information posted by you on
        BBBN including but not limited to photographs, images, graphics,
        designs, profiles, messages, information, notes, text, videos and all
        means of materials and content of any type may be used by BBBN within
        its platform only for purposes of servicing Users. We do not claim
        ownership over any User Generated Content (“UGC”) and by providing any
        UGC, you acknowledge and agree that BBBN reserves the right to refuse to
        a accept or transmit any UGC in its sole discretion;
      </p>
      <p className="text-lg font-bold mt-5">MOBILE APPLICATION PHONE USE</p>
      <p className="mt-3">
        You agree to adhere to any terms of any app store, mobile software
        platform, payment platform, or other third party services provided in
        connection with your use of the BBBN mobile application. We reserve all
        rights in and to the BBBN mobile application not expressly granted to
        you under this Agreement and you only have a limited license to use it
        to access the Service in accordance with these terms. By providing your
        mobile phone number and using the Service, you hereby affirmatively
        consent to our use of your mobile phone number for calls and texts from
        us and certain other Users relating to Requests relevant to you, such as
        those you submit. You understand and acknowledge that by communicating
        by calls or text, standard message charges or other charges from your
        wireless carrier may apply. You may opt-out of receiving text messages
        from us by modifying your User Account settings on the Website or App or
        by emailing &nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>
      </p>
      <p className="text-lg font-bold mt-5">SMA TERMS & CONDITIONS</p>
      <p className="mt-3">1. Opting into text messages:</p>
      <p className="mt-3">
        As stated above, by registering on BBBN you accept receiving messages
        and/or texts from BBBN. You expressly consent to receive recurring
        transactional or informational text messages related to bail bonds or
        the arrestee’s legal matter. Messages and/or texts may be sent using an
        automatic telephone dialing or messaging system to the cell phone or
        mobile number you provide us. You consent to receive transactional,
        operational, or informational text messages at that phone number,
        including but not limited to order confirmations; notifications;
        multi-factor authentication, etc. Please note we may not be able to
        deliver messages to all mobile carriers. Consent to receive texts is not
        a condition of purchasing any goods or services, and you understand and
        agree that all text messages may be sent using automated technology.
        Message and data rates may apply.
      </p>
      <p className="mt-3">2. How to Opt Out and Get Help</p>
      <p className="mt-3">
        To stop receiving text messages from BBBN, you agree to reply STOP to
        the number sending the message. After replying STOP, you may receive
        additional communications confirming that your request has been received
        and processed, and you may continue to receive text messages for a short
        period while BBBN processes your request. If you need further
        assistance, text HELP to the number sending the message, or contact BBBN
        at&nbsp;
        <a className="text-primary underline" href="tel:1-844-930-2663">
          1-844-930-2663
        </a>{" "}
        or &nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>{" "}
        or the BBBN Live Chat located on the below right.
      </p>
      <p className="mt-3">3. Cost</p>
      <p className="mt-3">
        Message and data rates may apply for any messages sent to you from or on
        behalf of BBBN, and any messages you may send to us. If you have any
        questions about your text plan or data plan, it is best to contact your
        wireless provider.
      </p>
      <p className="mt-3">4. Message Frequency</p>
      <p className="mt-3">
        The frequency of the amount of texts sent out by BBBN will again only be
        transaction or related to information regarding the associated legal
        matter.
      </p>
      <p className="text-lg font-bold mt-5">ELECTRONIC COMMUNICATIONS</p>
      <p className="mt-3">
        The communications between you and BBBN via the services use electronic
        means. For contractual purposes, you consent to receive communications
        from us in electronic form, and you agree that all terms and conditions,
        agreements, notices, disclosures, and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p className="text-lg font-bold mt-5">DISCLAIMER OF WARRANTIES</p>
      <p className="mt-3">
        The Services and the Content are provided to you on an “as is” and “as
        available” basis, without warranties of any kind, and BBBN hereby
        disclaims all warranties, whether express, implied, statutory, or
        otherwise, including but not limited to any implied warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
        Neither BBBN nor any person associated with BBBN makes any
        representation or warranty with respect to the completeness, security,
        reliability, quality, accuracy, or availability of the Services or the
        Content. Without limiting the foregoing, neither BBBN nor anyone
        associated with BBBN represents or warrants that the Services or the
        Content will be accurate, reliable, error-free, or uninterrupted, that
        defects will be corrected, that the Services, the Content, or the
        servers that make them available are free of viruses or other harmful
        components, or that the Services or the Content will otherwise meet your
        needs or expectations.
      </p>
      <p className="text-lg font-bold mt-5">LIMITATION OF LIABILITY</p>
      <p className="mt-3">
        To the fullest extent permitted by law, in no event will BBBN or its
        officers, directors, employees, agents, licensors, or service providers
        be liable for any direct, indirect, incidental, consequential, special,
        punitive, or exemplary damages arising from or related to your use of or
        inability to use the Services or Content, including but not limited to,
        personal injury or death, loss of revenue, loss of profits, loss of
        business, loss of use, or loss of data, whether caused by tort
        (including negligence), breach of contract, or otherwise, even if
        foreseeable. If, notwithstanding the provisions of this section, BBBN is
        found liable for any loss, damage, or injury under any legal theory
        relating in any way to the subject matter of these Terms of Use, in no
        event will BBBN’s total liability to you or any third party exceed the
        total amount of fees, if any paid by you to BBBN in the twelve month
        period preceding the event giving rise to the claim or U.S. $100,
        whichever is less. The foregoing limitations shall apply even if your
        remedies under these Terms of Use fail their essential purpose. Use of
        the Services are at your own risk.
      </p>
      <p className="text-lg font-bold mt-5">INDEMNIFICATION</p>
      <p className="mt-3">
        You agree to indemnify, defend, and hold BBBN and its officers,
        directors, employees, agents, licensors, and service providers harmless
        from and against any claims, liabilities, losses, damages, judgments,
        awards, costs, and expenses (including reasonable attorneys’ fees)
        arising out of or resulting from your use of the Services and/or any
        Content, or any violation of these Terms of Use or applicable law.
      </p>
      <p className="text-lg font-bold mt-5">SEVERABILITY</p>
      <p className="mt-3">
        These Terms of Use incorporate by reference any notices contained on the
        website and/or mobile application, the Privacy Notice, or Copyright and
        Trademark information, and constitute the entire agreement with respect
        to access to and use of this website and mobile application. In the
        event there is a conflict amongst these Terms of Use is unlawful, the
        remaining provisions and shall not affect their validity and
        enforceability.
      </p>
      <p className="text-lg font-bold mt-5">THIRD-PARTY MATERIALS</p>
      <p className="mt-3">
        BBBN may provide the Services to you through third-party websites,
        operating systems, platforms, and portals (collectively, “Third-Party
        Platforms”) Additional terms and conditions may apply to you with
        respect to your use of Third-Party Platforms, which are not under BBBN’s
        control. BBBN does not assume any responsibility or liability for your
        use of such Third-Party Platforms.
      </p>
      <p className="text-lg font-bold mt-5">
        GOVERNING LAW; JURISDICTION AND VENUE
      </p>
      <p className="mt-3">
        These Terms of Use and any dispute or claim arising out of or related to
        these Terms of Use, their subject matter, or their formation shall be
        governed by and construed in accordance with the laws of the State of
        Texas, other than such laws and case law that would result in the
        application of the laws of jurisdiction other than the State of Texas.
      </p>
      <p className="text-lg font-bold mt-5">QUESTIONS AND COMPLAINT</p>
      <p className="mt-3">
        If you have any questions about our privacy practices, these Terms of
        Use, would like to report unethical behavior, or if you would like to
        make a complaint, please contact us at &nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>
      </p>
      <p className="text-lg font-bold mt-5">MISCELLANEOUS TERMS</p>
      <p className="mt-3">
        If any provision of these terms shall be unlawful, void, or for any
        reason unenforceable under contract law, then that provision, or portion
        thereof, shall be deemed separate from the rest of this contract and
        shall not affect the validity and enforceability of any remaining
        provisions, or portions thereof. This is the entire agreement between
        vendor and client relating to the subject matter herein and shall not be
        modified except in writing, signed by both parties. In the event of a
        conflict between parties, client agrees to solve any arguments via
        arbitration. If the printer fails to print out photos on site the vendor
        will be allowed to give a web site to the client where their guests can
        log onto and print pictures free of charge with the ability to download
        the digital files for their own use. Vendor is not responsible for any
        consequential damages or lost opportunities upon breach of this
        agreement.
      </p>
      <p className="mt-3">
        If any provision of these terms shall be unlawful, void, or for any
        reason unenforceable under contract law, then that provision, or portion
        thereof, shall be deemed separate from the rest of this contract and
        shall not affect the validity and enforceability of any remaining
        provisions, or portions thereof. This is the entire agreement between
        vendor and client relating to the subject matter herein and shall not be
        modified except in writing, signed by both parties. In the event of a
        conflict between parties, client agrees to solve any arguments via
        arbitration. If the printer fails to print out photos on site the vendor
        will be allowed to give a web site to the client where their guests can
        log onto and print pictures free of charge with the ability to download
        the digital files for their own use. Vendor is not responsible for any
        consequential damages or lost opportunities upon breach of this
        agreement.
      </p>
      <p className="text-xl underline font-bold mt-6">Privacy Policy</p>
      <p className="text-lg font-bold mt-5">Privacy Policy</p>
      <p className="mt-3">
        BBBN is committed to protecting the privacy of our users. This Privacy
        Policy outlines how we collect, use, disclose, and manage your
        information.
      </p>
      <p className="text-lg font-bold mt-5">Information Collection and Use</p>
      <p className="mt-3">
        We may collect personal information, such as your name, email address,
        phone number, or other details, when you voluntarily provide it to us
        through forms or by interacting with our website. Additionally, we may
        automatically collect certain information about your device and usage of
        the website, including IP address, browser type, and cookies for
        analytical and statistical purposes.
      </p>
      <p className="text-lg font-bold mt-5">Use of Information</p>
      <p className="mt-3">
        We use the information we collect to provide and improve our services,
        respond to inquiries, send transactional and informational
        communications. We do not sell, trade, or transfer your personally
        identifiable information to third parties without your consent, except
        as required by law.
      </p>
      <p className="text-lg font-bold mt-5">Data Security</p>
      <p className="mt-3">
        We implement reasonable security measures to protect your information
        from unauthorized access, alteration, disclosure, or destruction.
        However, no method of transmission over the internet or electronic
        storage is completely secure, and we cannot guarantee absolute security.
      </p>
      <p className="text-lg font-bold mt-5">Cookies</p>
      <p className="mt-3">
        Our website may use cookies and similar tracking technologies to enhance
        your experience. You can manage your cookie preferences through your
        browser settings. Please note that disabling cookies may affect certain
        features of the website.
      </p>
      <p className="text-lg font-bold mt-5">Children’s Privacy</p>
      <p className="mt-3">
        Our services are not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children. If you are a
        parent or guardian and believe your child has provided us with personal
        information, please contact us, and we will take steps to remove that
        information.
      </p>
      <p className="text-lg font-bold mt-5">Changes to This Privacy Policy</p>
      <p className="mt-3">
        We reserve the right to update or modify this Privacy Policy at any
        time. Any changes will be effective immediately upon posting the updated
        policy on this page.
      </p>
      <p className="text-lg font-bold mt-5">Contact Us</p>
      <p className="mt-3">
        If you have any questions or concerns about our Privacy Policy, or if
        you wish to access, correct, or delete your personal information, please
        contact us at &nbsp;
        <a className="text-primary underline" href="tel:1-844-930-2663">
          1-844-930-2663
        </a>{" "}
        or &nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>{" "}
        or the Live Chat below.
      </p>
    </div>
  );
};

export default ToS;
