import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import avatar from "../assets/image/avatar.png";
import { AuthContext } from "../providers/Authentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { database } from "../firebase";
import axios from "axios";

const BidPage = () => {
  const {
    state: { job },
  } = useLocation();
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [totalBondPrice, setTotalBondPrice] = useState("");
  const [paymentPlanTerms, setPaymentPlanTerms] = useState("");
  const [timeline, setTimeline] = useState("");
  const [collateral, setCollateral] = useState("");
  const [gPhone, setGPhone] = useState("");

  useEffect(() => {
    setGPhone(job.gPhone);
    // eslint-disable-next-line
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "totalBondPrice") {
      setTotalBondPrice(value);
    } else if (name === "paymentPlanTerms") {
      setPaymentPlanTerms(value);
    } else if (name === "timeline") {
      setTimeline(value);
    } else if (name === "collateral") {
      setCollateral(value);
    }
  };

  const onPlaceBid = async (e) => {
    e.preventDefault();
    try {
      const postKey = database.ref().child("BT").push().key;
      database
        .ref(`BT/${postKey}`)
        .set({
          JobId: job.JobId,
          UserUid: currentUser.UserUid,
          TotalBondPrice: totalBondPrice,
          PaymentPlanTerms: paymentPlanTerms,
          Timeline: timeline,
          Collateral: collateral,
          BidId: postKey,
          CreatorId: job.UserUid,
        })
        .then(async (res) => {
          const bcRef = database.ref(`BCT/${currentUser.UserUid}`);
          await bcRef.update({
            Counter: currentUser.Counter - 1,
          });
          const message1 =
            "You have received an Incoming Bid for Bail. Please login to view. Truly, BailBondBidsNow.com";
          const MESSAGING_SERVICE_SID = "MG0586a3762e0a043ad2079097f60403d6";
          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendSMS",
              {
                to: gPhone,
                body: message1,
                messagingServiceSid: MESSAGING_SERVICE_SID,
              },
              { headers: { "Content-Type": "application/json" } }
            )
            .then(async function (response) {})
            .catch(function (error) {});
          history.push("/congratulation");
        })
        .catch((err) => {
          console.log("err", err);
          toast("Bid Failed!");
        });
    } catch (error) {
      console.log("errror", error);
      toast("Bid Failed!");
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <div className="flex items-center mb-4">
          <img
            src={job.icon ? job.icon : avatar}
            alt="..."
            className="w-16 h-16 rounded-full"
          />
          <div className="ml-4">
            <p>Bonding Company Name: {currentUser.Name}</p>
            <p>Bonding Company Phone: {currentUser.Phone}</p>
            <p>Bonding Company Email: {currentUser.Email}</p>
          </div>
        </div>
        Bonding Company Total Bond Price:
        <input
          className="mt-2 mb-4 p-2 w-96 border border-black-400"
          name="totalBondPrice"
          value={totalBondPrice}
          placeholder=""
          id="totalBondPrice"
          onChange={(event) => onChangeHandler(event)}
        />
        Bonding Company Payment Plan Terms:
        <input
          className="mt-2 mb-4 p-2 w-96 border border-black-400"
          name="paymentPlanTerms"
          value={paymentPlanTerms}
          placeholder=""
          id="paymentPlanTerms"
          onChange={(event) => onChangeHandler(event)}
        />
        Bonding Company Required Collateral:
        <input
          className="mt-2 mb-4 p-2 w-96 border border-black-400"
          name="collateral"
          value={collateral}
          placeholder=""
          id="collateral"
          onChange={(event) => onChangeHandler(event)}
        />
        Bonding Company Timeline to File Bond:
        <input
          className="mt-2 mb-4 p-2 w-96 border border-black-400"
          name="timeline"
          value={timeline}
          placeholder=""
          id="timeline"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-96 ${
            totalBondPrice && timeline && paymentPlanTerms && collateral
              ? "bg-primary"
              : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPlaceBid(event);
          }}
          disabled={
            !totalBondPrice || !timeline || !paymentPlanTerms || !collateral
          }
        >
          Submit Bid to Arrestee's Family/Friend
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BidPage;
