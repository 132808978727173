import React, { useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import axios from "axios";

const JobsPage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [accepted, setAccepted] = useState(false);
  const { state } = useLocation();
  const { name, birth, phone, facility, isShowCongratsPage } = state || {
    name: "",
    birth: "",
    phone: "",
    facility: "",
    isShowCongratsPage: false,
  };

  const onPress = async (e) => {
    e.preventDefault();
    const postKey = database.ref().child("JT").push().key;
    await database.ref(`JT/${postKey}`).set({
      UserUid: currentUser.UserUid,
      JobId: postKey,
      RequestedAt: new Date().toISOString(),
    });

    const message =
      "Thank you for submitting your request for Bids. You will receive emails and/or phone notifications when competitive incoming Bids arrive. Truly, BailBondBidsNow.com";
    const MESSAGING_SERVICE_SID = "MGebf06d379c79cfef40b63abea0290421";
    console.log("Phone==>", currentUser.FFSR?.Phone, phone);
    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendSMS",
        {
          to: currentUser.FFSR?.Phone || phone,
          body: message,
          messagingServiceSid: MESSAGING_SERVICE_SID,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async function (response) {
        console.log("response", response);
      })
      .catch(function (error) {
        console.log("error", error);
      });

    if (isShowCongratsPage) history.push("/postsuccess");
    else history.push("/myjobs");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-6 text-center">
        <p className="font-bold mt-10 text-lg text-center">
          Please Confirm Your Request to Receive Competitive Bids for Bail
          Regarding you Detained Family/Friend:
        </p>
        <p className="mt-10 text-lg">
          Arrestee's Name: {name || currentUser.Arrestee.Name}
        </p>
        <p className="mt-1 text-lg">
          Arrestee's Birth: {birth || currentUser.Arrestee.Birthday}
        </p>
        <p className="mt-1 text-lg">
          Arrestee's Current Jail / Detention Facility:{" "}
          {facility || currentUser.Arrestee.Facility}
        </p>
        <label className="block my-2">
          <input
            type="checkbox"
            name="accepted"
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
          />
          &nbsp;&nbsp;&nbsp;The incoming competitive Bids for Bail will show
          above under the category of <b>"Incoming Bids for Bail"</b>
        </label>
        <button
          className={`w-48 ${
            accepted ? "bg-primary" : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
          disabled={!accepted}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default JobsPage;
