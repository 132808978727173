import React, { useContext, useState } from "react";
import { AuthContext } from "../providers/Authentication";
import { auth } from "../firebase";
import logo from "../assets/image/logo.png";

export default function Navbar() {
  const { currentUser } = useContext(AuthContext);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onOptionClicked = () => {
    setMenuIsOpen(false);
  };

  const handleMouseOver = () => {
    setMenuIsOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuIsOpen(false);
  };

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-gray-800">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex flex-start lg:w-auto lg:static lg:block lg:justify-start">
            <button
              className="text-white cursor-pointer text-xl leading-none px-2 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fa fa-bars"></i>
            </button>
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white flex items-center"
              href="/"
            >
              BailBondBidsNow
              <img
                src={logo}
                alt="..."
                className="shadow align-middle border-none ml-2 w-8 h-8"
              />
            </a>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/"
                >
                  <i className="fa fa-home text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Home</span>
                </a>
              </li>
              <li className="nav-item">
                {currentUser && (
                  <div
                    className="relative cursor-pointer"
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                      <i className="fa fa-user text-lg leading-lg text-white opacity-75"></i>
                      <span className="ml-2">Account Management</span>
                    </div>
                    {menuIsOpen && (
                      <div className="absolute border border-solid border-black z-50">
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/assistant"
                              >
                                <i className="fa fa-question-circle text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">
                                  BBBN Process & Assistance/Help
                                </span>
                              </a>
                            </li>
                          </div>
                        </ul>
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/terms"
                              >
                                <i className="fa fa-legal text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">
                                  Terms & Policy and Privacy Policy
                                </span>
                              </a>
                            </li>
                          </div>
                        </ul>
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/contactus"
                              >
                                <i className="fa fa-phone text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">Contact Us</span>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/profile"
                  >
                    <i className="fa fa-user-circle text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">
                      {currentUser.userType === 0
                        ? "Arrestee's Profile"
                        : "Company Profile"}
                    </span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && currentUser.userType === 0 && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/jobs"
                  >
                    <i className="fa fa-briefcase text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Requested Bids for Bail</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/myjobs"
                  >
                    <i className="fa fa-briefcase text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">
                      {currentUser.userType === 0
                        ? "Incoming Bids for Bail"
                        : "Requested Bids for Bail"}
                    </span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && currentUser.userType === 1 && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/selectedbids"
                  >
                    <i className="fa fa-briefcase text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Selected Bids</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {!!currentUser ? (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/"
                    onClick={() => {
                      auth.signOut();
                    }}
                  >
                    <i className="fa fa-sign-out text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">LogOut</span>
                  </a>
                ) : (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="signin"
                  >
                    <i className="fa fa-sign-in text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">LogIn / Register</span>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
